<template>
  <div>
    <h5>PAGE NOT FOUND</h5>
    <router-link v-if="isloggedIn" to="/orders">Return to home</router-link>
    <router-link v-if="!isloggedIn" to="/">Return to home</router-link>
  </div>
</template>
<script>
import Parse from "parse";
export default {
  data() {
    return {
      isloggedIn: false
    };
  },
  mounted() {
    this.isloggedIn = Parse.User.current() ? true : false;
  }
};
</script>
<style scoped></style>
